/**!
 *
 * Common Dependency:
 * 1. <script type="text/javascript" src="${requestScope.locResource}${requestScope.siteId}/app/site/scripts/neu.min.js"></script>
 * 2. <jsp:include page="_variable.jsp" />
 * Note: If Channel timezone is different from project timezone, please import moment.js(app\scripts\libs\moment-2012-2022.js)
 *
 * Version:
 * neupagenav.js: 1.3.20191031 (Depend on neu.js v1.24.20191029+)
 *
 * */
class PageNav
{
	configs =
			{
				navName: undefined,
				navExpand: 2,
				navDataReady: () => {},
				commonSolrRows: "12",
				enableProgramSolrAppSearch: false,
				programSolrFl: "sequence,pid,name,description,seoName,runtime,releaseDate,releaseDateLocal,beginDateTime,beginDateTimeLocal,purchaseTypeId,showTitle,seasonTitle,image,programCode,extUrl,episode,location,venue,eventId,tvRating,progType,masterImage",
				gameSolrFl: "GAME_ID,EXT_ID,NAME,SEO_NAME,AWAY_TEAM,AWAY_CODE,HOME_TEAM,HOME_CODE,GAME_DATE,GAME_DATE_LOCAL,GAME_END_DATE,GAME_END_DATE_LOCAL,GAME_STATE,LEAGUE,LEAGUE_ID,SPORT_ID,NOTES,DESCRIPTION,IMAGE,FREE,masterImage",
				categorySolrFl: "catId,seoName,name,seoName,description,leaf,show,image,season,sortBy,playReverse,parentId,parentIds,catId2,episodes,masterImage",
				setProgramSolrFl: (type, style) =>
				{
					return this.configs.programSolrFl;
				},
				setProgramSolrRows: (type, style) =>
				{
					return this.configs.commonSolrRows;
				},
				setProgramSolrOptionFq: (type, style) =>
				{
					return "";
				},
				setGameSolrFl: (type, style) =>
				{
					return this.configs.gameSolrFl;
				},
				setGameSolrRows: (type, style) =>
				{
					return this.configs.commonSolrRows;
				},
				setGameSolrOptionFq: (type, style) =>
				{
					return "";
				},
				setCategorySolrFl: (type, style) =>
				{
					return this.configs.categorySolrFl;
				},
				setCategorySolrRows: (type, style) =>
				{
					return this.configs.commonSolrRows;
				},
				setCategorySolrFq: (type, style) =>
				{
					return "";
				},
				currentFeedInterval: undefined,
				current2FeedInterval: undefined,
				customProgramHandler: undefined,
				customCategoryHandler: undefined,
				customGameHandler: undefined,
				nlUserStatus: undefined,
				thirdUserStatus: undefined,
			};
	_navMenus = [];
	navMenus = [];
	navMenusSourceData = {};
	navMenusExtraData = {};
	navMenusExtraPagingData = {};
	_menuDataCount = -1;
	_homeDL = null;
	_catIds = [];
	_catMap = {};
	currentEPGTimer = null;
	current2EPGTimer = null;

	constructor(options)
	{
		this.configs = {
			...this.configs,
			...options,
		};
		this.navMenus = [];
		this._initConfig();
		this._init();
	}
	
	_init()
	{
		this.getPageNavFeed(this.configs.navName, this.configs.navExpand);
	}
	
	async getPageNavFeed(pageNavName, expand)
	{
		!this.SITE_CONFIG && this._initConfig();
		let feedUrl =  this.SITE_CONFIG.LOC_SERVER + "home?format=json&pagenav=" + pageNavName + (expand ? "&expand=" + expand : "");
		let data = await this._getJSON(feedUrl);
		this._homeDL = data.dynamicLead;
		this._navMenus = data.navigation;
		this._processPageNavFeed(data.navigation);
		this.configs.navDataReady(this.navMenus);
		this.SITE_CONFIG.SOLR_PROGRAM && this.SITE_CONFIG.SOLR_CATEGORY && this._catIds.length > 0 && this._loadAllCategories();
	}
	
	getMenuDataByIndex(index, callback, refresh, solrDataPageNumber)
	{
		let currentMenu = this.navMenus[index];
		if(solrDataPageNumber)
		{
			if(currentMenu.paging && solrDataPageNumber && solrDataPageNumber <= currentMenu.paging.totalPagesNumber)
			{
				//TODO: Now only SOLR Type Support paging
				if(currentMenu.type == "0" || currentMenu.type == "7")
				{
					if(!refresh && this.navMenusExtraPagingData[index] && this.navMenusExtraPagingData[index][solrDataPageNumber])
					{
						currentMenu.data =  this.navMenusExtraPagingData[index][solrDataPageNumber];
						callback && callback(currentMenu);
					}
					else
					{
						this._getExtraMenuData(currentMenu, callback, refresh, solrDataPageNumber);
					}
				}
				else
				{
					console.log(`Current Nav Type ` + currentMenu.type + ` does NOT Support Paging`);
				}
			}
			else
			{
				console.log(`Invalid Page Number`);
			}
		}
		else if(currentMenu.type == "8_current" && ((this.configs.currentFeedInterval && (!currentMenu.link || currentMenu.link == "current")) || (this.configs.current2FeedInterval && currentMenu.link && currentMenu.link == "current2")))
		{
			let that = this;
			that._getExtraMenuData(currentMenu, callback, refresh);
			if(currentMenu.link && currentMenu.link == "current2")
			{
				if (this.current2EPGTimer !== null) {
					clearInterval(this.current2EPGTimer);
				}
				this.current2EPGTimer = setInterval(() => {
					that._getExtraMenuData(currentMenu, callback, refresh);
				}, this.configs.current2FeedInterval);
			}
			else
			{
				if (this.currentEPGTimer !== null) {
					clearInterval(this.currentEPGTimer);
				}
				this.currentEPGTimer = setInterval(() => {
					that._getExtraMenuData(currentMenu, callback, refresh);
				}, this.configs.currentFeedInterval);
			}
		}
		else if (currentMenu.dataReady && !refresh)
		{
			if(this.navMenusSourceData[index])
			{
				currentMenu.data = this.navMenusSourceData[index];
				callback && callback(currentMenu);
			}
			if(currentMenu.paging && currentMenu.paging.currentPageNumber !== 1)
			{
				currentMenu.paging.currentPageNumber = 1;
				currentMenu.data =  this.navMenusExtraPagingData[index][1];
				callback && callback(currentMenu);
			}
			else if(this.navMenusExtraData[index])
			{
				currentMenu.data = this.navMenusExtraData[index];
				callback && callback(currentMenu);
			}
			else 
			{
				currentMenu.data = null;
				callback && callback(currentMenu);
			}
		}
		else
		{
			this._getExtraMenuData(currentMenu, callback, refresh);
		}
	}
	
	async _getExtraMenuData(menu, callback, refresh, solrDataPageNumber)
	{
		try
		{
			let feedUrl = "";
			let solrData = "";
			switch (menu.type)
			{
				case "0":
					if(this.SITE_CONFIG.SOLR_PROGRAM && this.SITE_CONFIG.SOLR_CATEGORY)
					{
						let category = null;
						let paging = null;
						if(this._catMap[menu.typeId] && !refresh)
						{
							category = this._catMap[menu.typeId];
						}
						else
						{
							feedUrl = this._processSolrFeedUrl('category', "catId:" + menu.typeId, menu.type, menu.style, true);
							solrData = await this._getJSONP(feedUrl);
							if(solrData.response.docs.length > 0)
							{
								category = window.NEU.processSolrCategory(solrData.response.docs[0]);
							}
							else
							{
								console.log(`Can't find Category which id is ` + menu.typeId);
								menu.data = null;
								callback && callback(menu);
								break;
							}
						}
						if(category.isLeaf)
						{
							if(category.programCount && category.programCount > 0)
							{
								let sortQuery = "&sort=" + NEU.getProgramSolrSortParam(category.id, category.sortBy);
								let startIndex = solrDataPageNumber ? Math.round(parseInt(solrDataPageNumber - 1, 10) * menu.paging.pageSize) : 0;
								feedUrl = this._processSolrFeedUrl('program', "catId:" + menu.typeId + sortQuery, menu.type, menu.style, true, startIndex);
								solrData = await this._getJSONP(feedUrl);
								category.programs = window.NEU.processSolrPrograms(solrData.response.docs);
								if(solrData.response.numFound > category.programs.length)
								{
									paging = {};
									paging.pageSize = menu.paging && menu.paging.pageSize ? menu.paging.pageSize : category.programs.length;
									paging.totalPagesNumber = Math.ceil(solrData.response.numFound / paging.pageSize);
									paging.currentPageNumber = solrData.response.start === 0 ? 1: (Math.ceil(startIndex / paging.pageSize) + 1);
								}
								else if(menu.paging)
								{
									paging = {};
									paging.totalPagesNumber = Math.ceil(solrData.response.numFound / paging.pageSize);
									paging.currentPageNumber = paging.totalPagesNumber;
								}
							}
							else 
							{
								category.programs = [];
							}
							menu.dataType = "category";
						}
						else 
						{
							let sortQuery = "&sort=rank_" + menu.typeId + "%20asc,score%20desc,catId%20desc";
							let startIndex = solrDataPageNumber ? Math.round(parseInt(solrDataPageNumber - 1, 10) * menu.paging.pageSize) : 0;
							feedUrl = this._processSolrFeedUrl('category', "parentIds:" + menu.typeId + sortQuery, menu.type, menu.style, true, startIndex);
							solrData = await this._getJSONP(feedUrl);
							category.subCategories = window.NEU.processSolrCategories(solrData.response.docs);
							category.categoryCount = solrData.response.numFound;
							if(solrData.response.numFound > category.subCategories.length)
							{
								paging = {};
								paging.pageSize = menu.paging && menu.paging.pageSize ? menu.paging.pageSize : category.subCategories.length;
								paging.totalPagesNumber = Math.ceil(solrData.response.numFound / paging.pageSize);
								paging.currentPageNumber = solrData.response.start === 0 ? 1: (Math.ceil(startIndex / paging.pageSize) + 1);
							}
							else if(menu.paging)
							{
								paging = {};
								paging.totalPagesNumber = Math.ceil(solrData.response.numFound / paging.pageSize);
								paging.currentPageNumber = paging.totalPagesNumber;
							}
							menu.dataType = "categories";
						}
						menu.data = category;
						menu.paging = paging;
						if(menu.data.isLeaf && menu.data.programCount)
						{
							if(menu.data.programCount > menu.data.programs.length)
							{
								menu.dataHasMore = true;
							}
						}
						else if(!menu.data.isLeaf && menu.data.categoryCount)
						{
							if(menu.data.categoryCount > menu.data.subCategories.length)
							{
								menu.dataHasMore = true;
							}
						}
					}
					else 
					{
						feedUrl = this.SITE_CONFIG.LOC_SERVER + "service/category?id=" + menu.typeId + "&format=json";
						let category  = await this._getJSON(feedUrl);
						category = this._processAppCategoryFeed(category);
						if(category.isLeaf)
						{
							menu.dataType = "category";
							if(category.programs == null)
							{
								category.programs = [];
							}
						}
						else
						{
							menu.dataType = "categories";
							menu.dataType = "category";
							if(category.subCategories == null)
							{
								category.subCategories = [];
							}
						}
						menu.data = category;
					}
					menu.dataReady = true;
					if(menu.data.isLeaf && menu.data.programCount)
					{
						if(menu.data.programCount > menu.data.programs.length)
						{
							menu.dataHasMore = true;
						}
					}
					this._menuDataCount--;
					if(solrDataPageNumber)
					{
						this.navMenusExtraPagingData[menu.index] = this.navMenusExtraPagingData[menu.index] ? this.navMenusExtraPagingData[menu.index] : {};
						this.navMenusExtraPagingData[menu.index][solrDataPageNumber] = menu.data;
					}
					else
					{
						this.navMenusExtraData[menu.index] = menu.data;
						this.navMenusExtraPagingData[menu.index] = this.navMenusExtraPagingData[menu.index] ? this.navMenusExtraPagingData[menu.index] : {};
						this.navMenusExtraPagingData[menu.index][1] = menu.data;
					}
					callback && callback(menu);
					break;
				case "1":
					feedUrl = this.SITE_CONFIG.LOC_SERVER + "service/channel?id=" + menu.typeId + "&format=json";
					let channel = await this._getJSON(feedUrl);
					if(this.SITE_CONFIG.LOC_EPG && channel.epg)
					{
						let feedDate = this.CLIENT_CONFIG.currentLocalDate;
						let localeString = "";
						//If Channel timezone is different from project timezone, please import moment.js
						if(channel.timeZone && window.moment)
						{
							feedDate = window.moment(parseInt(this.CLIENT_CONFIG.currentDateTime)).tz(channel.timeZone).format("YYYY-MM-DDTHH:mm:ss.SSS");
						}
						if(this.CLIENT_CONFIG.locale && this.CLIENT_CONFIG.locale != 'en_US')
						{
							localeString = "_" + this.CLIENT_CONFIG.locale;
						}
						feedUrl = this.SITE_CONFIG.LOC_EPG + channel.seoName.toLowerCase() + "/" + this._getFormatDate(feedDate) + localeString + ".json";
						channel.epgList = await this._getJSON(feedUrl);
					}
					menu.data = channel;
					menu.dataType = "channel";
					menu.dataReady = true;
					this._menuDataCount--;
					this.navMenusExtraData[menu.index] = menu.data;
					callback && callback(menu);
					break;
				case "2":
					feedUrl = this.SITE_CONFIG.LOC_SERVER + "category/latest?format=json";
					menu.dataType = "program";
					menu.data = await this._getJSON(feedUrl);
					menu.dataReady = true;
					this._menuDataCount--;
					this.navMenusExtraData[menu.index] = menu.data;
					callback && callback(menu);
					break;
				case "6":
					feedUrl = this.SITE_CONFIG.LOC_SERVER + "category/most-watched?format=json";
					menu.dataType = "program";
					menu.data = await this._getJSON(feedUrl);
					menu.dataReady = true;
					this._menuDataCount--;
					this.navMenusExtraData[menu.index] = menu.data;
					callback && callback(menu);
					break;
				case "7":
					let startIndex = solrDataPageNumber ? Math.round(parseInt(solrDataPageNumber - 1, 10) * menu.paging.pageSize) : 0;
					feedUrl = this._processSolrFeedUrl(menu.solrType, menu.solrQuery, menu.type, menu.style, false, startIndex);
					solrData = await this._getJSONP(feedUrl);
					menu.dataType = menu.solrType;
					menu.data = this._processSolrData(menu.solrType, solrData);
					menu.dataReady = true;
					let paging = null;
					if(solrData.response.numFound > menu.data.length)
					{
						menu.dataHasMore = true;
						paging = {};
						paging.pageSize = menu.paging && menu.paging.pageSize ? menu.paging.pageSize : menu.data.length;
						paging.totalPagesNumber = Math.ceil(solrData.response.numFound / paging.pageSize);
						paging.currentPageNumber = solrData.response.start === 0 ? 1: (Math.ceil(startIndex / paging.pageSize) + 1);
					}
					else if(menu.paging)
					{
						paging = {};
						paging.totalPagesNumber = Math.ceil(solrData.response.numFound / paging.pageSize);
						paging.currentPageNumber = paging.totalPagesNumber;
					}
					menu.paging = paging;
					this._menuDataCount--;
					if(solrDataPageNumber)
					{
						this.navMenusExtraPagingData[menu.index] = this.navMenusExtraPagingData[menu.index] ? this.navMenusExtraPagingData[menu.index] : {};
						this.navMenusExtraPagingData[menu.index][solrDataPageNumber] = menu.data;
					}
					else
					{
						this.navMenusExtraData[menu.index] = menu.data;
						this.navMenusExtraPagingData[menu.index] = this.navMenusExtraPagingData[menu.index] ? this.navMenusExtraPagingData[menu.index] : {};
						this.navMenusExtraPagingData[menu.index][1] = menu.data;
					}
					callback && callback(menu);
					break;
				case "8_dl":
					feedUrl = this.SITE_CONFIG.LOC_SERVER + "home?dlname=" + menu.dlName + "&format=json";
					menu.dataType = "dl";
					let homeJson = await this._getJSON(feedUrl);
					menu.data = homeJson.dynamicLead;
					menu.dataReady = true;
					this._menuDataCount--;
					this.navMenusExtraData[menu.index] = menu.data;
					callback && callback(menu);
					break;
				case "8_current":
					let timestamp = 60;
					feedUrl = this.SITE_CONFIG.LOC_EPG + (menu.link ? menu.link : "current") + ".json";
					menu.dataType = "epg";
					let currentEPG = await this._getJSONWithTimestamp(timestamp, feedUrl);
					menu.data = currentEPG;
					menu.dataReady = true;
					this._menuDataCount--;
					this.navMenusExtraData[menu.index] = menu.data;
					callback && callback(menu);
					break;
				case "9":
					if(window.ps)
					{
						if(menu.link === "game")
						{
							solrData = await this._getRecommendGames(menu.type, menu.style);
							menu.dataType = "game";
							menu.data = this._processSolrData("program", solrData);
						}
						else if(menu.link === "category")
						{
							solrData = await this._getRecommendCategories(menu.type, menu.style);
							menu.dataType = "category";
							menu.data = this._processSolrData("program", solrData);
						}
						else
						{
							solrData = await this._getRecommendPrograms(menu.type, menu.style);
							menu.dataType = "program";
							menu.data = this._processSolrData("program", solrData);
						}
						menu.dataReady = true;
						this._menuDataCount--;
						this.navMenusExtraData[menu.index] = menu.data;
						callback && callback(menu);
					}
					break;
				case "10":
					if(window.ps)
					{
						solrData = await this._getRecentlyViewedPrograms(menu.type, menu.style);
						menu.dataType = "program";
						menu.data = this._processSolrData("program", solrData);
						menu.dataReady = true;
						this._menuDataCount--;
						this.navMenusExtraData[menu.index] = menu.data;
						callback && callback(menu);
					}
					break;
				case "11":
					if(window.ps)
					{
						solrData = await this._getPlayListPrograms();
						menu.dataType = "program";
						menu.data = solrData;
						// TODO: Now playlist nlps API only return ids
/*						menu.data = this._processSolrData("program", solrData);*/
						menu.dataReady = true;
						this._menuDataCount--;
						this.navMenusExtraData[menu.index] = menu.data;
						callback && callback(menu);
					}
					break;
				case "12":
					feedUrl = this.SITE_CONFIG.LOC_SERVER + "packages?format=json";
					if (!this.SITE_CONFIG.HAS_PURCHASE && this.SITE_CONFIG.HAS_BUNDLE_PURCHASE)
					{
						feedUrl += "&type=0";
					}
					menu.dataType = "package";
					menu.data = await this._getJSON(feedUrl);
					menu.dataReady = true;
					this._menuDataCount--;
					this.navMenusExtraData[menu.index] = menu.data;
					callback && callback(menu);
					break;
				case "13":
					feedUrl = this.SITE_CONFIG.LOC_SERVER + "channels?format=json";
					menu.dataType = "channel";
					menu.data = await this._getJSON(feedUrl);
					menu.dataReady = true;
					this._menuDataCount--;
					this.navMenusExtraData[menu.index] = menu.data;
					callback && callback(menu);
					break;
				case "15":
					if(window.ps)
					{
						// TODO: Now favourite nlps API only return ids
						if(menu.link)
						{
							solrData = await this._getFavouriteList(menu.link);
							menu.dataType = menu.link;
						}
						else
						{
							solrData = await this._getFavouriteList("program");
							menu.dataType = "program";
						}
						menu.data = solrData;
						menu.dataReady = true;
						this._menuDataCount--;
						this.navMenusExtraData[menu.index] = menu.data;
						callback && callback(menu);
					}
					break;
			}
		}
		catch (e)
		{
			console.log(`Get Menu [` + menu.index + `] Extra Data error:`, e);
		}
	}
	_processAppCategoryFeed(category)
	{
		var ret = null;
		if(category && category.seoName)
		{
			category.seoName = category.seoName.toLowerCase();
		}
		ret = category;
		return ret;
	}
	
	_processSolrFeedUrl(type, query, menuType, menuStyle, isFilter, startIndex)
	{
		let ret = null;
		let userSearch = "usersearch";
		let solrPrefix = null;
		let solrKey = "?q=";
		let solrFl = null;
		let solrRows = null;
		let solrQuery = query;
		let solrOptionFq = "";
		let solrStartIndex = "";
		if(type == 'program')
		{
			if(this.configs.customProgramHandler)
			{
				userSearch = this.configs.customProgramHandler;
			}
			else if(this.configs.enableProgramSolrAppSearch)
			{
				userSearch = "appsearch";
			}
			solrPrefix = this.SITE_CONFIG.SOLR_PROGRAM;
			solrRows = this.configs.setProgramSolrRows(menuType, menuStyle) ? this.configs.setProgramSolrRows(menuType, menuStyle) : this.configs.commonSolrRows;
			solrFl = this.configs.setProgramSolrFl(menuType, menuStyle);
			solrOptionFq = this.configs.setProgramSolrOptionFq(menuType, menuStyle);
		}
		else if(type == 'game')
		{
			if(this.configs.customGameHandler)
			{
				userSearch = this.configs.customGameHandler;
			}
			solrPrefix = this.SITE_CONFIG.SOLR_GAME;
			solrRows = this.configs.setGameSolrRows(menuType, menuStyle) ? this.configs.setGameSolrRows(menuType, menuStyle) : this.configs.commonSolrRows;
			solrFl = this.configs.setGameSolrFl(menuType, menuStyle);
			solrOptionFq = this.configs.setGameSolrOptionFq(menuType, menuStyle);
		}
		else if(type == 'category')
		{
			if(this.configs.customCategoryHandler)
			{
				userSearch = this.configs.customCategoryHandler;
			}
			solrPrefix = this.SITE_CONFIG.SOLR_CATEGORY;
			solrRows = this.configs.setCategorySolrRows(menuType, menuStyle) ? this.configs.setCategorySolrRows(menuType, menuStyle) : this.configs.commonSolrRows;
			solrFl = this.configs.setCategorySolrFl(menuType, menuStyle);
			solrOptionFq = this.configs.setCategorySolrFq(menuType, menuStyle);
		}
		if(isFilter)
		{
			if(solrOptionFq)
			{
				solrKey = "";
				solrQuery = "?fq=" + solrQuery;
				solrQuery = this._processFilterWithOptionFq(solrQuery, solrOptionFq).replace(/\?fq=/, "?q=*:*&fq=");
			}
			else
			{
				solrKey = "?q=*:*&fq="
			}
		}
		else if(solrOptionFq != null && solrOptionFq != "")
		{
			solrQuery = this._processQueryWithOptionFq(solrQuery, solrOptionFq);
		}
		if(startIndex)
		{
			solrStartIndex = "&start=" + startIndex;
		}
		ret = solrPrefix + userSearch + solrKey + solrQuery + "&rows=" + solrRows + solrStartIndex + "&fl=" + solrFl + "&wt=json&json.wrf=[cb]";
		return ret;
	}

	_processFilterWithOptionFq(query, solrOptionFq)
	{
		let ret = null;
		ret = "";
		let solrStingArr = query.split('&');
		{
			for (let i = 0; i < solrStingArr.length; i++)
			{
				if(solrStingArr[i].indexOf("?fq=") != -1)
				{
					let fqValue = solrStingArr[i].split('=')[1];
					ret = ret + "?fq=" + "(" + fqValue + ")" + "%20AND%20" + "(" + solrOptionFq + ")";
				}
				else
				{
					ret += (i !==0 ? "&" : "")  + solrStingArr[i];
				}
			}
		}
		return ret;
	}

	_processQueryWithOptionFq(query, solrOptionFq)
	{
		let ret = null;
		if(query.indexOf("&fq=") != -1)
		{
			ret = "";
			let solrStingArr = query.split('&');
			{
				for (let i = 0; i < solrStingArr.length; i++)
				{
					if(solrStingArr[i].indexOf("fq=") != -1)
					{
						let fqValue = solrStingArr[i].split('=')[1];
						ret = ret + "&fq=" + "(" + fqValue + ")" + "%20AND%20" + "(" + solrOptionFq + ")";
					}
					else 
					{
						ret += (i !==0 ? "&" : "")  + solrStingArr[i];
					}
				}
			}
		}
		else 
		{
			ret = query + "&fq=" + solrOptionFq;
		}
		return ret;
	}
	
	_processSolrData(type, solrData)
	{
		let ret = null;
		let data = null;
		if(solrData.response)
		{
			data = solrData.response.docs;
		}
		else 
		{
			data = solrData;
		}
		if(type == 'program')
		{
			ret = window.NEU.processSolrPrograms(data);
		}
		else if(type == 'game')
		{
			ret = window.NEU.processSolrGames(data);
		}
		else if(type == 'category')
		{
			ret = window.NEU.processSolrCategories(data);
		}
		return ret;
	}
	
	_processPageNavFeed(menus)
	{
		this.navMenus = [];
		this._catIds = [];
		if(menus.length > 0)
		{
			let currentNLUserStatus = this.configs.nlUserStatus !== undefined ? this.configs.nlUserStatus : window.NEU.processNlUserStatus(this.CLIENT_CONFIG.isLoggedIn, (this.CLIENT_CONFIG.isLoggedIn && this.CLIENT_CONFIG.user.isSubscriber));
			let pageNavMenus = menus.filter(item => window.NEU.filterUserTypeByStatus(item.userType, currentNLUserStatus, this.configs.thirdUserStatus));
			this._menuDataCount = pageNavMenus.length;
			for (let i = 0; i < pageNavMenus.length; i++)
			{
				let menu =  pageNavMenus[i];
				let item = {};
				item.index = i;
				item.type = menu.type;
				item.typeId = menu.typeId;
				item.name = menu.name;
				item.description = menu.description;
				item.link = menu.link;
				item.style = menu.style;
				if(menu.userType)
				{
					item.userType = menu.userType;
				}
				this._processMenuSourceData(item, menu);
				this.navMenus[this.navMenus.length] = item;
				if((this.configs.navExpand === undefined || i > parseInt(this.configs.navExpand) - 1) && menu.type === "0")
				{
					this._catIds[this._catIds.length] = menu.typeId;
				}
			}
		}
	}

	filterMenusByThirdUserStatus(thirdUserStatus, callback)
	{
		this.configs.thirdUserStatus = thirdUserStatus;
		this._processPageNavFeed(this._navMenus);
		callback && callback(this.navMenus);
	}

	async _loadAllCategories()
	{
		let q = '"' + this._catIds.join('" "') + '"';
		let query = encodeURIComponent("catId:(" + q + ")");

		let userSearch = this.configs.customCategoryHandler ? this.configs.customCategoryHandler : "usersearch";
		let feedUrl = this.SITE_CONFIG.SOLR_CATEGORY + userSearch + "?q=*:*&fq=" + query + "&rows=5000&fl=" + this.configs.categorySolrFl + "&wt=json&json.wrf=[cb]";
		let solrData = await this._getJSONP(feedUrl);
		let categories = window.NEU.processSolrCategories(solrData.response.docs);
		if(categories.length > 0)
		{
			for (let i = 0; i < categories.length; i++)
			{
				this._catMap[categories[i].id] = categories[i];
			}
		}
	}

	_processMenuSourceData(item, menu)
	{
		switch (menu.type)
		{
			case "0":
				if (menu.category) //When menu is in expand index
				{
					this._checkCategoryData(item, menu);
				}
				break;
			case "2":
				if (menu.programs) //When menu is in expand index
				{
					item.dataType = "program";
					item.dataReady = true;
					let sourceData = {};
					sourceData.programs = menu.programs;
					this.navMenusSourceData[item.index] = sourceData;
					this._menuDataCount--;
				}
				break;
			case "3":
				item.dataReady = true;
				this._menuDataCount--;
				break;
			case "4":
				item.dataReady = true;
				this._menuDataCount--;
				break;
			case "5":
				item.dataReady = true;
				this._menuDataCount--;
				break;
			case "6":
				if (menu.programs) //When menu is in expand index
				{
					item.dataType = "program";
					item.dataReady = true;
					let sourceData = {};
					sourceData.programs = menu.programs;
					this.navMenusSourceData[item.index] = sourceData;
					this._menuDataCount--;
				}
				break;
			case "7":
				item.solrType = menu.link.split(":")[0];
				item.solrQuery = menu.link.substring(item.solrType.length + 1);
				break;
			case "8":
				let linkType = menu.name.split(":")[0];
				if(linkType != null)
				{
					if (linkType == "dl")
					{
						item.type = "8_dl";
						item.dlName = menu.link;
						item.name = menu.name.substring(linkType.length + 1);
						if(menu.link == "home" && this._homeDL != null)
						{
							item.dataType = "dl";
							item.dataReady = true;
							this.navMenusSourceData[item.index] = this._homeDL;
							this._menuDataCount--;
						}
					}
					else if(linkType == "current" && this.SITE_CONFIG.LOC_EPG && ((this.configs.currentFeedInterval && (!menu.link || menu.link == "current")) || (this.configs.current2FeedInterval && menu.link && menu.link == "current2")))
					{
						item.type = "8_" + linkType;
						item.name = menu.name.substring(linkType.length + 1);
						this._menuDataCount--;
					}
					else
					{
						item.type = "8_" + linkType;
						item.name = menu.name.substring(linkType.length + 1);
						item.dataReady = true;
						this._menuDataCount--;
					}
				}
				else 
				{
					item.dataReady = true;
					this._menuDataCount--;
				}
				break;
			case "14":
				item.dataReady = true;
				this._menuDataCount--;
				break;
		}
	}
	
	_checkCategoryData(item, menu)
	{
		if(menu.category.programs)
		{
			item.dataType = "category";
			item.dataReady = true;
			this.navMenusSourceData[item.index] = this._processAppCategoryFeed(menu.category);
			this._menuDataCount--;
			if(menu.category.programCount > menu.category.programs.length)
			{
				item.dataHasMore = true;
			}
		}
		else if(menu.category.subCategories)
		{
			item.dataType = "categories";
			item.dataReady = true;
			this.navMenusSourceData[item.index] = this._processAppCategoryFeed(menu.category);
			this._menuDataCount--;
		}
	}
	
	_initConfig()
	{
		let siteConfig = {};
		if (window.config)
		{
			siteConfig = window.config;
		}
		else
		{
			siteConfig = 
			{
				LOC_SERVER: window.LOC_SERVER,
				LOC_EPG: window.LOC_EPG,
				SOLR_PROGRAM: window.SOLR_PROGRAM,
				SOLR_CATEGORY: window.SOLR_CATEGORY,
				SOLR_GAME: window.SOLR_GAME,
			};
		}
		this.SITE_CONFIG = siteConfig;
		
		let clientConfig = null;
		if (window.client)
		{
			clientConfig = window.client;
		}
		else
		{
			clientConfig = 
			{
				isLoggedIn: window.isLoggedIn,
				user: {
					isSubscriber: window.isSubscriber
				}
			};
		}
		this.CLIENT_CONFIG = clientConfig;
	}

	destroy() {
		// Destroy timeout
		if (this.currentEPGTimer !== null) {
			clearInterval(this.currentEPGTimer);
		}
		if (this.current2EPGTimer !== null) {
			clearInterval(this.current2EPGTimer);
		}

		this.navMenus = [];
		this.navMenusSourceData = {};
		this.navMenusExtraData = {};
		this.navMenusExtraPagingData = {};
		this._menuDataCount = -1;
		this._homeDL = null;
		this._catIds = [];
		this._catMap = {};
	}


	/**
	 * Utils
	 * ---------------------------------------------------------
	 */
	_getJSONWithTimestamp(timestamp, feedUrl)
	{
		return new Promise(resolve => window.NEU.getJSON(NEU.util.addTimestamp(feedUrl, timestamp), data => resolve(data)));
	}

	_getJSON(feedUrl)
	{
		return new Promise(resolve => window.NEU.getJSON(feedUrl, data => resolve(data)));
	}
	
	_getJSONP(feedUrl)
	{
		return new Promise(resolve => window.NEU.getJSONP(feedUrl, data => resolve(data)));
	}
	
	_getFormatDate(dateString)
	{
		return (dateString.split("-")[0] + '/' + dateString.split("-")[1] + '/' + dateString.split("-")[2].substring(0, 2));
	}
	
	_getRecommendPrograms(type, style)
	{
		return new Promise(resolve =>
		{
			ps.getRecommendedPrograms(null, {ps: this.configs.setProgramSolrRows(type, style), pn: 1}, data => resolve(data), {fl: this.configs.setProgramSolrFl(type, style)});
		});
	};

	_getRecommendGames(type, style)
	{
		return new Promise(resolve =>
		{
			ps.getRecommendedGames(null, {ps: this.configs.setGameSolrRows(type, style), pn: 1}, data => resolve(data), {fl: this.configs.setProgramSolrFl(type, style)});
		});
	};

	_getRecommendCategories(type, style)
	{
		return new Promise(resolve =>
		{
			ps.getRecommendedCategories(null, {ps: this.configs.setCategorySolrRows(type, style), pn: 1}, data => resolve(data), {fl: this.configs.setCategorySolrFl(type, style)});
		});
	};

	_getRecentlyViewedPrograms(type, style)
	{
		return new Promise(resolve =>
		{
			ps.getRecentlyViewedPrograms({ps: this.configs.setProgramSolrRows(type, style), pn: 1}, data => resolve(data), {fl: this.configs.setProgramSolrFl(type, style)});
		});
	};
	
	_getPlayListPrograms()
	{
		return new Promise(resolve =>
		{
			ps.playlist.getItems(null, null, data => resolve(data));
		});
	};

	_getFavouriteList(parameters)
	{
		return new Promise(resolve =>
		{
			ps.favorite.list(null, {type: parameters}, data => resolve(data));
		});
	};
}

export default PageNav